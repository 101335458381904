<template>
  <div class="h100">
      <el-card class="box-card">
    <el-row>
      <el-col :span="20">
        <el-button type="primary" @click="bindStatus">关联</el-button>
        <el-button @click="unBindStatus">取消关联</el-button>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="bindStatus">关联</el-button>
        <el-button @click="unBindStatus">取消关联</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input type="text" v-model="searchGlobalId"
        v-on:keyup.enter.native="searchAllListByGlobalId"
            placeholder="输入globalId搜索全部列"></el-input>
      </el-col>
      <el-col :span="3">
        <el-button @click="resetAllGlobal">重置全部列globalId</el-button>
      </el-col>
      <el-col :span="3">
        <el-button @click="resetAllSearchCondtion">重置全部列搜索条件</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="3" v-for="(videoData,index) in videoDataList" :key="index">
        <el-row>
          <el-col :span="24">
            <el-input type="text" v-model="videoData.search.pictureId"
            :key="index" @input="inputchange" 
            v-on:keyup.enter.native="searchList(videoData.search,index,true)"
            placeholder="输入图片id搜索"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-input type="text" v-model="videoData.search.carId" 
            :key="index" @input="inputchange" 
            v-on:keyup.enter.native="searchList(videoData.search,index,true)"
            placeholder="输入车辆id搜索"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-input type="text" v-model="videoData.search.globalId" 
            :key="index" 
            @input="inputchange" 
            v-on:keyup.enter.native="searchList(videoData.search,index,true)"
            placeholder="输入globalId搜索"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button size="mini" type="success" @click="searchList(videoData.search,index,true)">搜索</el-button>
            
          </el-col>
          <el-col :span="12">
            <el-button size="mini" @click="resetSearch(index)">重置</el-button>
          </el-col>
        </el-row>
        
        <el-row>
          <el-col :span="24" style="font-size:14px;">
              当前{{videoData&&videoData.page&&videoData.page.currentPage}}页｜共{{videoData&&videoData.page&&videoData.page.pages}}页
          </el-col>
        </el-row>
        <el-row style="font-size:14px;">
          <el-col :span="7">
              跳转到
          </el-col>
          <el-col :span="8">
              <input
                  class="page-input"
                  size="mini"
                  min="1"
                  type="number"
                  :max ="videoData.page.pages"
                  @keyup.enter="goPage(videoData.page.goToPageNumber,index)"
                  v-model="videoData.page.goToPageNumber" />
          </el-col>
          <el-col :span="6">页</el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-button type="text" @click="goToPage(0,index)">上一页</el-button>
            <el-button type="text" @click="goToPage(1,index)">下一页</el-button>
          </el-col>
          <el-col :span="10">
            <el-tooltip v-if="videoData.searchDataInListPage" class="item" effect="dark" placement="top-start">
              <div slot="content">当前搜索条件在所有数据中的页数<br/>点击跳转到该页，但同时会清空搜索条件</div>
              <el-button  type="text" style="color:#67C23A" @click="clearSearchGotoPage(videoData.searchDataInListPage,index)">第{{videoData.searchDataInListPage}}页</el-button>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-table
        :data="videoData.list"
          border
          style="width: 100%">
          <el-table-column
            prop="img"
            :label="'摄像头'+videoData.videoName">
            <template slot-scope="props">
              <!-- <div style="position:relative;top:0;left:0">
                
              </div> -->
              <div style="position:relative;">
                <el-checkbox class="car-trace" style="position:absolute;right:20px;bottom:14px;width:0px;height:20px" v-model="props.row.checked"></el-checkbox>
                <el-image
                style="width: 100%; height: 100px;"
                :src="imgUrl+props.row.url.replace('/root/file','')"
                fit="contain"
                @click="viewImg(imgUrl+props.row.url.replace('/root/file',''))"
                ></el-image>
                <div class="img-info" @click="props.row.checked = !props.row.checked">
                  <div style="font-weight: bold;">{{props.row.url.split('/')[props.row.url.split('/').length-1]}}</div>
                  <div>图片Id：{{props.row.pictureId}}</div>
                  <div>视频时间：约{{secondsToHMS(Number(props.row.pictureId/30).toFixed(0))}}</div>
                  <div>车辆Id：{{props.row.carId}}</div>
                  <div>globalId：{{props.row.globalId}}</div>
                </div>
              </div>

            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center;color:grey"> {{'摄像头'+videoData.videoName}}</div>
       <div>
        <el-row>
          <el-col :span="14">
            <el-button type="text" @click="goToPage(0,index)">上一页</el-button>
            <el-button type="text" @click="goToPage(1,index)">下一页</el-button>
          </el-col>
          <el-col :span="10">
            <el-tooltip v-if="videoData.searchDataInListPage" class="item" effect="dark" placement="top-start">
              <div slot="content">当前搜索条件在所有数据中的页数<br/>点击跳转到该页，但同时会清空搜索条件</div>
              <el-button  type="text" style="color:#67C23A" @click="clearSearchGotoPage(videoData.searchDataInListPage,index)">第{{videoData.searchDataInListPage}}页</el-button>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="font-size:14px;">
              当前{{videoData&&videoData.page&&videoData.page.currentPage}}页｜共{{videoData&&videoData.page&&videoData.page.pages}}页
          </el-col>
        </el-row>
        <el-row style="font-size:14px;">
          <el-col :span="7">
              跳转到
          </el-col>
          <el-col :span="8">
              <input
                  class="page-input"
                  size="mini"
                  min="1"
                  type="number"
                  :max ="videoData.page.pages"
                  @keyup.enter="goPage(videoData.page.goToPageNumber,index)"
                  v-model="videoData.page.goToPageNumber" />
          </el-col>
          <el-col :span="6">页</el-col>
        </el-row>
        
        <!-- <el-row>
          <el-button type="primary" size="mini" @click="goPage(videoData.page.goToPageNumber,index)">确定</el-button>
        </el-row> -->
       </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20">
        <el-button type="primary" @click="bindStatus">关联</el-button>
        <el-button @click="unBindStatus">取消关联</el-button>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="bindStatus">关联</el-button>
        <el-button @click="unBindStatus">取消关联</el-button>
      </el-col>
    </el-row>
    <el-dialog title="图片查看" :visible.sync="dialogFormVisible" @close="closeWindow">
        <el-row>
          <el-col>
            <el-image
                style="width: 100%; height: 400px"
                :src="currentSrc"
                fit="contain"
                ></el-image>
          </el-col>
        </el-row>
        <!-- <img :src="currentSrc" style="width:300px;height: 100%;" /> -->
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeWindow">关闭</el-button>
        </div>
      </el-dialog>

  <el-backtop target=".h100" :right="80" :bottom="50">
  </el-backtop>
</el-card>
    </div>

</template>

<script>
import {list,bindStatus,unBindStatus} from '@/api/carTrace/carTraceOld'
export default {
  data() {
    return {
      searchGlobalId:'',
      initObj:{
        videoId:'',
          videoName:'',
          list:[],
          search:{
              pictureId:'',
              carId:'',
              globalId:''
            },
          page:{
              currentPage:1,
              total:0,
              pages:0
          },
          searchDataInListPage:null
      },
      videoDataList:[],
      currentSrc:null,
      dialogFormVisible:false,
      imgUrl:process.env.VUE_APP_BASE_API,
      selectArr:'',
      hasGlobalnot0:false,
    }
  },
  created(){
      for(let i=0;i<=7;i++){
          this.videoDataList.push(this.initObj)
      }
      for(let i=1;i<9;i++){
        this.getList(1,i)
      }
      
  },
  methods: {
    secondsToHMS(seconds) {
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var remainingSeconds = seconds % 60;
      
      return hours + ":" + minutes + ":" + remainingSeconds;
    },
    inputchange(){
      this.$forceUpdate();
    },
      bindStatus(){
          // 关联数据
          let arr =this.getCheckedList(true)
          
          if(!arr){
            this.$message.error('请至少选择两组摄像头的数据')
            return;
          }
          if(arr.indexOf(',')==-1){
            this.$message.error('请至少选择两条数据')
            return;
          }
          if(this.hasGlobalnot0){
            // globalId !=0提示消息
            this.$confirm('当前选择中有globalId已绑定的数据，如继续绑定所选数据将设置为已存在的globalId的最小值，是否继续？', '提示', {
              confirmButtonText: '继续绑定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              bindStatus(arr).then(res => {
                if(res.data.code == 200){

                    this.$message.success('关联成功！')
                    this.hasGlobalnot0 = false
                    this.reloadList()
                }
              }).catch(() => {
                this.selectArr = ''
              });
            }).catch(() => {
              this.hasGlobalnot0 = false
            });
          }else{
            this.$confirm('确定关联已选图片?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              bindStatus(arr).then(res => {
                if(res.data.code == 200){

                    this.$message.success('关联成功！')
                    this.reloadList()
                }
              }).catch(() => {
                this.selectArr = ''
              });
            }).catch(() => {

            });
          }

          
          
      },
      unBindStatus(){
          // 取消关联数据
          let arr =this.getCheckedList(false)
          //console.log(arr)
          // if(!arr){
          //   this.$message.error('请至少选择两组摄像头的数据')
          //   return;
          // }
          if(!arr){
            this.$message.error('请至少选择一条数据')
            return;
          }
          this.$confirm('确定取消关联已选图片?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            unBindStatus(arr).then(res => {
              if(res.data.code == 200){
                  this.$message.success('取消关联成功！')
                  this.reloadList()
              }
            }).catch(() => {
              this.selectArr = ''
            });
          }).catch(() => {

          });
          
      },
      getCheckedList(type){
          let checkedArr = []
          for(let i=0;i<this.videoDataList.length;i++){
              for(let j=0;j<this.videoDataList[i].list.length;j++){
                  if(this.videoDataList[i].list[j].checked){
                      if(this.selectArr.indexOf(i) == -1){
                        this.selectArr+=i
                      }
                      // 如果数据中 global!=0 ，弹提示消息 
                      if(this.videoDataList[i].list[j].globalId != 0){
                         this.hasGlobalnot0 = true;
                      }
                      checkedArr.push(this.videoDataList[i].list[j].id)
                  }
              }
          }
          if(Boolean(type) && this.selectArr.split('').length<=1){
            return false
          }
          return checkedArr.join(',');
      },
    viewImg(src){
      this.currentSrc = src 
      this.dialogFormVisible = true
    },
    closeWindow(){
      this.dialogFormVisible = false;
    },
    goPage(page,index){
      if(page<1 || page>this.videoDataList[index].page.pages){
          this.$message.error('页数在1到'+this.videoDataList[index].page.pages+'之间');
          return;
      }

      //this.videoDataList[index].page.currentPage = page;
      let parames = {
          pageNum:page,
          search:this.videoDataList[index].search,
          pageSize:10,
          videoId:this.videoDataList[index].videoId
      }
      this.refreshDataList(parames,index)
    },
    resetSearch(index){
      this.videoDataList[index].search.pictureId = '';
      this.videoDataList[index].search.carId = '';
      this.videoDataList[index].search.globalId = '';
      this.videoDataList[index].searchDataInListPage = 0;
      this.searchList(this.videoDataList[index].search,index,false);

    },
    resetAllSearchCondtion(){
      for(let i =0;i<=8;i++){
        this.searchGlobalId = '';
        this.videoDataList[i].search.globalId = this.searchGlobalId;
        this.videoDataList[i].search.pictureId = '';
        this.videoDataList[i].search.carId = '';
        this.videoDataList[i].searchDataInListPage = 0;
        this.searchList(this.videoDataList[i].search,i,false);
      }
    },
    resetAllGlobal(){
      for(let i =0;i<=8;i++){
        this.searchGlobalId = '';
        this.videoDataList[i].search.globalId = this.searchGlobalId;
        this.searchList(this.videoDataList[i].search,i,true);
      }
    },
    searchAllListByGlobalId(){
      for(let i =0;i<8;i++){
        this.videoDataList[i].search.globalId = this.searchGlobalId;
        this.searchList(this.videoDataList[i].search,i,true);
      }

    },
    searchList(search,index,setSearchDataInListPage){
      let parames = {
          pageNum:1,
          pageSize:10,
          search,
          videoId:this.videoDataList[index].videoId
      }
      this.refreshDataList(parames,index,setSearchDataInListPage)
      
    },
    refreshDataList(parames,index,setSearchDataInListPage){
      list(parames).then(res => {
          let result = res.data;
          for(let i = 0;i<result.records.length;i++){
              result.records[i].checked = false
          }
          this.videoDataList[index].list = result.records;
          this.videoDataList[index].page.currentPage = parames.pageNum;
          this.videoDataList[index].page.total = result.total;
          this.videoDataList[index].page.pages = result.pages;
          this.videoDataList[index].page.goToPageNumber = null;
          if(setSearchDataInListPage){
            this.videoDataList[index].searchDataInListPage = result.countId?Number(result.countId):0;
          }
          

          this.$forceUpdate()
      })
    },
    goToPage(plus,index){
      // 0:上一页 1:下一页
      let tempPage = 1
      if(plus){
        //下一页
          this.videoDataList[index].page.currentPage=Number(this.videoDataList[index].page.currentPage)+1;
          tempPage = (this.videoDataList[index].page.currentPage <= this.videoDataList[index].page.pages)?this.videoDataList[index].page.currentPage:this.videoDataList[index].page.pages;
      }else{
        //上一页
        this.videoDataList[index].page.currentPage=Number(this.videoDataList[index].page.currentPage)-1;
          tempPage = (this.videoDataList[index].page.currentPage >1 )?this.videoDataList[index].page.currentPage:1;
      }
      
  
      //this.videoDataList[index].page.currentPage = tempPage;
      let parames = {
          pageNum:tempPage,
          pageSize:10,
          search:this.videoDataList[index].search,
          videoId:this.videoDataList[index].videoId
      }
      this.refreshDataList(parames,index)
       
    }, 
    reloadList(){
      let arr = this.selectArr.split('');
      this.selectArr = '';
     
      for(let i = 0;i< arr.length;i++){
          let parames = {
            pageNum:this.videoDataList[arr[i]].page.currentPage,
            pageSize:10,
            search:this.videoDataList[arr[i]].search,
            videoId:this.videoDataList[arr[i]].videoId
          }
          this.refreshDataList(parames,arr[i])
      }
    },
    clearSearchGotoPage(currentPage,index){
      this.videoDataList[index].search.pictureId = '';
      this.videoDataList[index].search.carId = '';
      this.videoDataList[index].search.globalId = '';
      this.videoDataList[index].searchDataInListPage = null;
      let parames = {
          pageNum:currentPage,
          pageSize:10,
          search:this.videoDataList[index].search,
          videoId:this.videoDataList[index].videoId
      }
      this.refreshDataList(parames,index)
    },
    getList (currentPage,videoId) {
      let parames = {
          pageNum:currentPage,
          search:this.videoDataList[videoId-1].search,
          pageSize:10,
          videoId:videoId
      }
      list(parames).then(res => {

        let result = res.data;
        if(result.records.length>0){
          let videoId = result.records[0].videoId;
          let obj ={
            videoId:result.records[0].videoId,
            videoName:result.records[0].videoName,
            list:result.records,
            search:{
              pictureId:'',
              carId:'',
              globalId:''
            },
            page:{
                currentPage:currentPage,
                total:result.total,
                pages:result.pages,
                goToPageNumber:null
            },
            searchDataInListPage:null
          }
        for(let i = 0;i<obj.list.length;i++){
          obj.list[i].checked = false
        }
        
        this.videoDataList[videoId-1] = obj;
        this.$forceUpdate()
        }
        
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row{
    margin-bottom: 5px;
  }
.fixed {

position: fixed;

top: 0;

left: 0;

right: 0;

z-index: 99;

}
.page-input{
    height:20px !important;
    line-height:20px !important;
    padding:0 5px !important;
    border:1px solid #DCDFE6;
    border-radius: 5px;
  }
  .img-info{
    z-index:999;font-size:12px;color: #f00;
  }
  
//  .el-checkbox__input .el-checkbox__inner {
//       height:28px !important;
//       width:28px !important;
//     }

  .car-trace {

::v-deep .el-checkbox__inner {
 
  width:28px;
  height:28px;
}
::v-deep .el-checkbox__input .el-checkbox__inner::after {
  // 对号
  border: 2px solid #fff;
  // 不覆盖下面的 会 导致对号变形
  box-sizing: content-box;
  content: "";
  border-left: 0;
  border-top: 0;
  height: 17px;
  left: 8px;
  position: absolute;
  top: 1px;
  width: 8px;
  //
  transform: rotate(45deg) scaleY(1);
}
}
</style>

